//import './plugins/axios'
import { createApp } from 'vue'
import App from './App.vue'
import router from './utils/router'
import axios from './utils/axios'
import Antd from 'ant-design-vue';
// import 'ant-design-vue/dist/antd.css';
import 'ant-design-vue/dist/antd.less'; // 引入官方提供的 less 样式入口文件
import './assets/theme.less'; // 用于覆盖上面定义的变量
import { IconPark } from '@icon-park/vue-next/es/all';
import VueResizeObserver from "vue-resize-observer";
import print from 'vue3-print-nb'

const app = createApp(App);
app.component('IconPark',IconPark);
app.config.globalProperties.$axios = axios;
app.use(router).use(Antd).use(VueResizeObserver).use(print).mount('#app');

