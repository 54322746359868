<template>
  <!-- <a-spin size="large" :spinning="layoutLoading" > -->
  <a-layout style="min-height:100vh">
    <a-layout-header class="bw-layout-header">
      <!-- mini menu -->
      <div class="bw-mini-topmenu">
        <a-row type="flex">
          <a-col flex="100px"></a-col>
          <a-col flex="auto"></a-col>
          <a-col flex="50%">
            <div style="text-align: right;" >
              <a-popover trigger="hover">
                <template #content>
                  <a-list size="small">
                    <a-list-item>
                      <a-button type="text" @click="goEditPassword"><icon-park type="setting-two" theme="outline" />修改密码</a-button>
                    </a-list-item>
                    <a-list-item>
                      <a-button type="text" @click="logout"><icon-park type="power" theme="outline" />退出系统</a-button>
                    </a-list-item>
                  </a-list>
                </template>
                <a href="" style="margin-right:20px;color:#666;" ><icon-park type="user" theme="outline" /> {{username}}</a>
                <!-- <a-button type="link" style="margin-right:20px;"><icon-park type="user" theme="outline" /> {{username}}</a-button> -->
              </a-popover>
              <a-typography-text style="color:#be9f87;margin-right:20px;font-weight:bold;">
                <icon-park type="phone-call" theme="filled" style=""/>
                <span>0532-66025398</span>
              </a-typography-text>
              <router-link to="/" style="color:#666;">帮助中心</router-link>
            </div>
          </a-col>
        </a-row>
      </div>
      
      <!-- banner -->
      <div class="bw-top-search">
        <a-row type="flex">
          <a-col flex="300px">
            <div class="logo" style="text-align: left;" >        
              <img src="../assets/new-logo.png" />
            </div>
          </a-col>
          <a-col flex="auto"></a-col>
          <a-col flex="50%">
            <div style="text-align: left;" >
              <a-input-group compact>
                <a-input v-model:value="q" style="width: calc(100% - 100px);border:3px solid #438a66;" placeholder="搜索商品" size="large" @click="queryInventory"  />
                <a-button type="primary" style="width:100px;height:44px;" size="large"><icon-park type="search" theme="outline" /></a-button>
              </a-input-group>
            </div>
          </a-col>
        </a-row>
      </div>

      <div class="bw-top-menu">
        <a-row>
          <a-col :span="24">
            <a-menu mode="horizontal" :selectable="false">
              <a-menu-item key="home" style="padding-left:0px;">
                <div  class="bw-menu-item" style="width:300px;background-color: #438a66;">
                  <router-link to="/"><span style="font-weight:bold;color:#fff;"><icon-park type="home" theme="filled" /> 首页</span></router-link>
                </div>
              </a-menu-item>
              <a-menu-item v-for="item in menuData" :key="item.key" >
                <div class="bw-menu-item">
                  <router-link :to="item.path">{{ item.title }}</router-link>
                </div>
              </a-menu-item>
            </a-menu>
          </a-col>
        </a-row>
      </div>
    </a-layout-header>
    <a-layout-content style="">
      <!-- <div :style="{ background: '#fff', padding: '24px', minHeight: '280px' }"> -->
      <router-view :key="timestamp"/>
      <!-- </div> -->
    </a-layout-content>
    <a-layout-footer style="text-align: center">
      <div style="color:#666;">
        <span style="margin-right: 20px;">联系我们:0532-66025398</span>
        <span>地址: 山东省青岛市连云港路76号安泰广场20层2001-2004</span>
      </div>
      <span style="text-align: center;font-size:14px;color:#aaa;text-align: center;" >{{ footerInfo }}</span>
    </a-layout-footer>
  </a-layout>
  <!-- </a-spin> -->
  <!-- <NotificationList ref="NotificationList" ></NotificationList> -->
</template>

<script>
import { notification } from 'ant-design-vue';
// import { createVNode } from 'vue';
import defaultSettings from '@/config/defaultSettings'
// import { Home } from '@icon-park/vue-next/es/map';

// import NotificationList from '../views/components/drawer/NotificationList';

const customerMenu = [
  {code:'1',key:'contract',path:'/contracts',title:'我的合同'},
  {code:'2',key:'order',path:'/orders',title:'我的订单'}
];

const providerMenu = [
  {code:'2',key:'order',path:'/porders',title:'我的订单'},
  {code:'1',key:'note',path:'/pdeliverynotes',title:'我的送货单'}
  
]

export default {
  // components: { NotificationList },
  data() {
    return {
      q:'',
      username:'',
      usertype:'',
      menuData:[],

      title: defaultSettings.title,
      footerInfo:defaultSettings.footerInfo,
      timestamp:null,
      layoutLoading:false,

      unreadCount:0,
      noticData:[],
      unnoticCount:0,
      unnoticData:[],

      noticPopVisible:false,

      timer:null,

      current:['home'],
    };
  },
  provide(){
    return {
      reload: this.reloadRouterView
    }
  },
  mounted() {
    this.layoutLoading = true;
    this.$axios
    .get('api/customer/get_info')
    .then((res) => {
        this.username = res.data.username;
        this.usertype = res.data.usertype;
        if(this.usertype == 'provider'){
          this.menuData = providerMenu;
        }
        else{
          this.menuData = customerMenu;
        }
        this.layoutLoading = false;
    });
    //检查通知
    // this.startCheckNotice();
  },
  watch: {
    '$route' () {
      this.updateMenu();
    }
  },
  methods: {
    onOpenChange:function(openKeys) {
      this.openKeys = [openKeys.pop()];
    },
    updateMenu:function(){
      const routes = this.$route.matched.concat();
      const userType = routes.pop().meta.userType;
      // console.log(userType);
      if(userType != undefined){
        // console.log(userType != this.usertype);
        if(userType != this.usertype){
          this.$router.replace('/');
        }
      }
      // console.log(this.menuData);
      // this.menuData.forEach(item => {
      //   if(item.path == currentPath){
      //     this.selectedKeys = [item.code];
      //   }
      //   if(item.children){
      //     item.children.forEach(child => {
      //       if(child.path == currentPath){
      //         this.selectedKeys = [child.code];
      //         this.openKeys = [item.code];
      //       }
      //     })
      //   }        
      // })
    },
    goEditPassword:function(){      
      this.$router.push('/user/edit_password');
    },
    logout:function(){      
      localStorage.removeItem('gt-cw-token');
      localStorage.removeItem('gt-cw-ut');
      this.$router.replace('/login');
    },
    reloadRouterView:function(){
      this.timestamp = Date.parse(new Date());
    },
    //Notification
    showNotificationList:function(){
      this.noticPopVisible = false;
      this.$refs.NotificationList.show();
    },
    checkNotic:function(){
      this.$axios
       .get('/notification/get_new')
       .then((res) => {
          if(res.success){
            this.unreadCount = res.data.unreadCount;
            this.noticData = res.data.notic;
            this.unnoticCount = res.data.unnoticCount;
            this.unnoticData = res.data.unnotic;

            if(this.unnoticCount > 0){
              notification.open({
                description: '您有'+this.unnoticCount+'条新通知',
                // description: res.data.unnotic.map((item)=>{ return createVNode('div',{style:'font-size:12px;line-height:1.5'},item.text); })
              });
              //发送web notification
              // this.showWebNotification('您有'+this.unnoticCount+'条新通知');
            }
            
          }          
       });
    },
    queryInventory:function(){
      
    },
    startCheckNotice:function(){
      const _that = this;
      _that.checkNotic();
      _that.timer = setTimeout(()=>{
        _that.startCheckNotice();
      },1000 * 60 * 3); //三分钟执行一次
    },
    //web notification
    showWebNotification(notic) {
      // 检查浏览器是否支持Web Notification
      if (!("Notification" in window)) {
        // alert("该浏览器不支持Web通知");
      } 
      else if (Notification.permission === "granted") {
        // console.log('have permission');
        // 如果用户已经同意接收通知，则创建通知
        // var notification = new Notification(notic);
        new Notification("青岛冠通订单管理系统", {
          body: notic
        });
      } 
      else {
        // console.log('request permission');
        //请求权限
        Notification.requestPermission().then(function(permission) {
          if (permission === "granted") {
            new Notification("青岛冠通订单管理系统", {
              body: notic
            });
          }
          // Notification.permission = permission;
          permission;
        });
      }
    }
  },
}
</script>
<style scoped>
.logo { }
.logo img {
  width:250px;
}

.bw-layout-header {
  box-shadow: 0px 1px 4px rgb(0 21 41 / 20%);
  z-index: 10;
  background: #fff; 
  padding: 0;
  line-height: 1;
  height:auto;
}
.bw-flex-end {
  align-self: flex-end;
}

.bw-mini-topmenu {
  width:1280px;
  margin:0px auto;
  padding-top:8px;
  font-size:14px;
}
.bw-top-search {
  width:1280px;
  margin:0px auto;
  padding:8px 0px 8px 0px;
  /* border:1px solid #ccc; */
}

.bw-top-menu {
  width:1280px;
  margin:0px auto;
  /* padding:8px 0px; */
  /* border:1px solid #ccc; */
  height:40px;
  line-height:40px;
  margin-bottom:2px;
}
.bw-menu-item {
  width:100px;
  text-align: center;
  font-size:16px;
}

.bw-width-1280 {
  
}

/* 优先级不够，全部被覆盖 */
.avatar{
  margin-left:5px;
  color: #1890ff;
  background: hsla(0,0%,100%,.85);
  vertical-align: middle;
  font-size:24px;
}
.ant-layout-footer {
    padding-top:20px;
    padding-bottom:20px;
    text-align: center;
    font-size:12px;
    color:#aaa;
}
.bw-menu .i-icon {
    font-size:1em;
    vertical-align: -.15em;
    margin-right:5px;
}
</style>


