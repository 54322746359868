import axios from "axios";
import router from './router'
import qs from "qs";
import { message } from 'ant-design-vue';

// axios.defaults.baseURL = 'https://47.92.131.50'  //正式 
axios.defaults.baseURL = 'https://www.qdgtsz.com'  //正式
// axios.defaults.baseURL = 'http://127.0.0.1:8000' //测试
// axios.defaults.baseURL = '/test/'  //正式
const baseURL = axios.defaults.baseURL;
//post请求头
axios.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded;charset=UTF-8";
//允许跨域携带cookie信息
// axios.defaults.withCredentials = true;
//设置超时
axios.defaults.timeout = 15000;

axios.interceptors.request.use(
  config => {
    // do something before request is sent
    //每次请求之前判断是否存在token
    //如果存在，则统一在http请求的header上都加上token，以便后台根据token判断当前登陆情况
    //即使本地存在token，也有可能过期，所以需要对返回状态进行判断
    const token = localStorage.getItem('gt-cw-token');
    if(token){
      config.headers['Authorization'] = token; 
    }
    // if (['post', 'put', 'delete'].includes(config.method) && typeof config.data === 'object') {
    //   config.data = Object.keys(config.data).map(key => `${key}=${config.data[key]}`).join('&');
    //   config.data = encodeURI(config.data); // 解决参数中包含特殊字符无法保存的问题
    // }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  response => {
    if (response.status == 200) {
      // console.log(response);
      switch (response.data.code) {
        case 401:
        case '401':
          localStorage.removeItem('gt-cw-token');
          localStorage.removeItem('gt-cw-ut');
          message.error(response.data.msg)
          router.replace({
            path:'/login',
            query:{redirect:router.currentRoute.fullPath}
          });
          break
        case 403:
        case '403':
          message.error(response.data.msg)
          break
        default:
          return Promise.resolve(response)
      }
    }
    else {
      return Promise.reject(response);
    }
  },
  error => {
    if (error && error.response && error.response.status) {
      message.error(JSON.stringify(error))
      // switch (error.response.status) {
      //   case 401:
      //     localStorage.removeItem('gt-cw-token');
      //     message.error(error.response.data.msg)
      //     router.replace({
      //       path:'/login',
      //       query:{redirect:router.currentRoute.fullPath}
      //     });
      //     break
      //   case 403:
      //       message.error(error.response.data.msg)
      //       break
      //   default:
      //     message.error(JSON.stringify(error))
      //     // alert(JSON.stringify(error), '请求异常', {
      //     //   confirmButtonText: '确定',
      //     //   callback: (action) => {
      //     //     console.log(action)
      //     //   }
      //     // });
      //     break
      // }
    }
  }
);
export default {
  /**
   * @param {String} url 
   * @param {Object} data 
   * @returns Promise
   */
  post(url, data) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url,
        data: qs.stringify(data),
      })
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        reject(err)
      });
    })
  },

  get(url, data) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url,
        params: data,
      })
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err)
      })
    })
  },

  download(url,data){
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url,
        params: data,
        responseType : 'blob',
        timeout:1000*60*2
      })
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err)
      })
    })
  },
  baseURL
};