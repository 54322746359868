
import { createRouter, createWebHashHistory } from 'vue-router'
import { routerMap } from '@/config/router.config'
import { Modal } from 'ant-design-vue';

const router = createRouter({
  history: createWebHashHistory(),
  routes: routerMap
})

// const routerPush = router.push;
// router.push = path => {
//   // console.log(router.currentRoute,router.currentRoute.fullPath,path);
//   // if(router.currentRoute.fullPath.includes(path)) return;
//   return routerPush.call(router,path);
// }

router.beforeEach((to,from,next)=>{
  Modal.destroyAll();

  const token = localStorage.getItem('gt-cw-token');
  if(to.path == '/login'){
    if(token){
      next({path:'/'});
    }
    else{
      next();
    }
  }
  else{
    // if(to.meta.requireAuth === true){
    //   if(!token){
    //     next({path:'/login'});
    //   }
    //   else{
    //     next();
    //   }
    // }    
    if(!token){
      next({path:'/login'});
    }
    else{
      next();
    }    
  }
});

export default router
