import { BasicLayout } from '@/layouts'

export const routerMap = [
  {
    path: '/',
    name: 'home',
    component: BasicLayout,
    meta: { title: '/',requireAuth:true },
    // redirect: '/home',
    children: [
      {
        path: '/',
        component:()=>import('@/views/HomeView.vue'),
        meta: { },
      },
      {
        path: '/inventory/:id',
        component:()=>import('@/views/Inventory/DetailView.vue'),
        meta: { },
      },
      {
        path: '/orders',
        component:()=>import('@/views/Order/ListView.vue'),
        meta: { userType:'customer'},
      },
      {
        path: '/order/create',
        component:()=>import('@/views/Order/CreateView.vue'),
        meta: { userType:'customer'},
      },
      {
        path: '/order/:id/edit',
        component:()=>import('@/views/Order/EditView.vue'),
        meta: { userType:'customer'},
      },
      {
        path: '/order/:id/detail',
        component:()=>import('@/views/Order/DetailView.vue'),
        meta: { userType:'customer'},
      },
      {
        path: '/contracts',
        component:()=>import('@/views/Contract/ListView.vue'),
        meta: { userType:'customer'},
      },
      {
        path: '/contract/:id/detail',
        component:()=>import('@/views/Contract/DetailView.vue'),
        meta: { userType:'customer'},
      },

      {
        path: '/porders',
        component:()=>import('@/views/ProviderOrder/ListView.vue'),
        meta: { userType:'provider'},
      },
      {
        path: '/porder/:id/detail',
        component:()=>import('@/views/ProviderOrder/DetailView.vue'),
        meta: { userType:'provider'},
      },
      
      {
        path: '/pdeliverynotes',
        component:()=>import('@/views/ProviderDeliveryNote/ListView.vue'),
        meta: { userType:'provider'},
      },
      {
        path: '/pdeliverynote/create',
        component:()=>import('@/views/ProviderDeliveryNote/CreateView.vue'),
        meta: { userType:'provider'},
      },
      {
        path: '/pdeliverynote/:id/edit',
        component:()=>import('@/views/ProviderDeliveryNote/EditView.vue'),
        meta: { userType:'provider'},
      },
      {
        path: '/pdeliverynote/:id/detail',
        component:()=>import('@/views/ProviderDeliveryNote/DetailView.vue'),
        meta: { userType:'provider'},
      },
    ],
  },
  {path: '/login',component:()=>import('@/views/LoginView.vue')}  
]

/**
 * 基础路由
 * @type { *[] }
 */
// export const constantRouterMap = [
//   {
//     path: '/user',
//     component: UserLayout,
//     redirect: '/user/login',
//     hidden: true,
//     children: [
//       {
//         path: 'login',
//         name: 'login',
//         component: () => import(/* webpackChunkName: "user" */ '@/views/user/Login')
//       },
//       {
//         path: 'register',
//         name: 'register',
//         component: () => import(/* webpackChunkName: "user" */ '@/views/user/Register')
//       },
//       {
//         path: 'register-result',
//         name: 'registerResult',
//         component: () => import(/* webpackChunkName: "user" */ '@/views/user/RegisterResult')
//       },
//       {
//         path: 'recover',
//         name: 'recover',
//         component: undefined
//       }
//     ]
//   },

//   {
//     path: '/404',
//     component: () => import(/* webpackChunkName: "fail" */ '@/views/exception/404')
//   }
// ]
